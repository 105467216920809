.gpt3__cta {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family);
    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10.7236px;
    padding: 2rem;
}

.gpt3__cta-content{
    flex: 2.5;
    margin: auto 0;
}

.gpt3__cta h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
}

.gpt3__cta p {
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    cursor: pointer;

    color: #0E0E0E;
}

.gpt3__cta button {
    flex: 0.5;
    font-family: var(--font-family);
    color: #fff;
    background-color: #000000;
    border: 0;
    outline: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    border-radius: 4.5rem;
    cursor: pointer;
    margin: 2rem 0;
}

@media screen and (max-width:545px){
    .gpt3__cta{
        flex-direction: column;
        padding: 1.5rem;
    }
    .gpt3__cta h1{
        font-size: 16px;
        line-height: 30px;
    }
    .gpt3__cta button{
        font-size: 10px;
        padding: 0.5rem;
        width: 40%;
        margin: 1rem 0 0 0;
    }
}

@media screen and (min-width:545px) and (max-width:980px){
    .gpt3__cta{
        flex-direction: column;
        padding: 2rem;
    }
    .gpt3__cta h1{
        line-height: 30px;
        font-size: 20px;
    }
    .gpt3__cta button{
        padding: 1rem;
        width: 40%;
        margin: 1rem 0 0 0;
    }
}

@media screen and (min-width:950px){
    .gpt3__cta h1{
        font-size: 20px;
    }
    .gpt3__cta button{
        padding: 1rem ;
        border-radius: 2rem;
        
    }
}