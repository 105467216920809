.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.gpt3__possibility-image {
    /* display: flex; */
    margin: 0 1.5rem;
    flex: 1;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-container{
    flex: 1.5;
    font-family: var(--font-family);
    display: flex;
    justify-content:flex-end;
    flex-direction: column;
    margin: auto 1rem;
}

.gpt3__possibility-container h1 {
    font-weight: 800;
    font-size: 33px;
    line-height: 45px;
    margin: 1rem 0;

    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}


.gpt3__possibility-container p:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;

    color: #71E5FF;
}

.gpt3__possibility-container p:last-child {
    color: var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

@media screen and (max-width:960px){

    .gpt3__possibility{
        flex-direction: column;
    }
    .gpt3__possibility-container{
        margin: 2rem;
    }
    .gpt3__possibility-container h1{
        font-size: 25px;
        line-height: 30px;
    }
}