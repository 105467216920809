.gpt3__blog-container_article {
    display: flex;
    flex-direction: column;
    height: 440px;
    width: 300px;
    background-color: var(--color-footer);
    border: 1px solid #333;
    font-family: var(--font-family);
    margin-bottom: 25px;
    color: #fff;
    padding: 1rem;
}

@media (max-width: 650px) {
    .gpt3__blog-container_article {
        width: 100%;
        height: max-content;
    }

    .gpt3__blog-container_article-content {
        margin-bottom: 30px;
    }
}

.browse-icon {
    visibility: hidden;
}

.description::-webkit-scrollbar {
    display: none;
}

.tool-box:hover .gpt3__blog-container_article {
    border: 1px solid #FF4820;
    cursor: pointer;
}

.tool-box:hover .browse-icon {
    visibility: visible;
}

.gpt3__blog-container_article:hover {
    border: 1px solid #FF4820;
    cursor: pointer;
}


.common-btn {
    padding: 0.5rem 1rem;
    background-color: #FF4820;
    border-radius: 0.5rem;
    cursor: pointer;
    max-width: 100px;
    font-size: 18px;
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 25px;
    color: #fff;
    border: 0;
}

.gpt3__blog-container_article-image {
    /* width: 100%;
    height: 100%; */
    margin: 0px auto 0px auto;
    width: 160px;
    height: 160px;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    margin: 0px auto 0px auto;
    width: 160px;
    height: 160px;
}


.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1rem;
}

.gpt3__blog-container_article-content p {
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 24px;
}

.gpt3__blog-container_article-content h3 {
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 30px;
    padding-bottom: 1rem;
}